import { createStyles, makeStyles, Theme } from '@material-ui/core';

const CarStyles = makeStyles(({ palette }: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  folderTitleIcon: {
    height: 60,
    width: 60,
    marginRight: 10,
    color: '#01B3C5',
    verticalAlign: 'middle',
  },
  folderRow: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(1, 179, 197, 0.08)',
    },
  },
  folderIcon: {
    verticalAlign: 'middle',
    color: '#01B3C5',
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    background: '#4dcad6',
    '&:hover': {
      backgroundColor: '#01B3C5',
    },
    textTransform: 'unset',
  },
}), { name: 'Dashboard' });

export default CarStyles;
