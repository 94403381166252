import React, { useCallback } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { useActionDispatcher } from '../../infrastructure/hooks';
import { removeNotifications } from '../../state/context/notifications/actions';
import Notification from './Notification';

const makeTypeSet = (color: string) => ({
  color,
  background: '#FFF',
  borderBottomColor: color,
  borderBottom: 'solid 3px',
});

const useStyles = makeStyles(({ palette, breakpoints }: Theme) => createStyles({
  root: {
    minHeight: 50,
    // top: 20,
    [breakpoints.up('md')]: {
      right: 20,
    },
  },
  default: makeTypeSet(palette.text.primary),
  success: makeTypeSet('#01B3C5'),
  error: makeTypeSet('#F8221A'),
  warning: makeTypeSet('#F8221A'),
  info: makeTypeSet('#2979FF'),
}), { name: 'Notifications' });

const Notifications = () => {
  const classes = useStyles();
  const remove = useActionDispatcher(removeNotifications);
  const handleClose = useCallback((e: React.SyntheticEvent<any> | null, reason: string) => {
    if (reason !== 'clickaway') {
      remove();
    }
  }, [remove]);

  return (
    <div style={{ zIndex: 2247483647, position: 'absolute' }}>
      <SnackbarProvider
        maxSnack={5}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          root: classes.root,
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        hideIconVariant
        autoHideDuration={10000}
      >
        <Notification />
      </SnackbarProvider>
    </div>
  );
};

export default Notifications;
