import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { Archive } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccessToken } from '../../../auth/state/selectors';
import { logOut } from '../../../auth/state/actions';
import { downloadFile, isAccessToken } from '../../../../infrastructure/helpers/helpers';
import CarStyles from '../styles';
import {API_URL} from "../../../../infrastructure/obligations/constants";
import {selectFolderMetaData} from "../../state/selectors";

const DownloadAction = () => {
  const styles = CarStyles();
  const dispatch = useDispatch();
  const folderMetaData = useSelector(selectFolderMetaData);
  const token = useSelector(selectAccessToken);

  const downloadHandler = useCallback(() => {
    if (isAccessToken(token)) {
      let url = `${API_URL}/olympus/file/download?folderId=${folderMetaData.folderId}&token=${token}`;
      downloadFile(url, `${folderMetaData.folderName}_${Date.now()}.zip`);
    } else {
      dispatch(logOut());
    }
  }, [dispatch, token, folderMetaData]);

  return (
    <Button
      onClick={downloadHandler}
      variant="outlined"
      startIcon={<Archive />}
      className={styles.button}
    >
      Скачать архив
    </Button>
  );
};

export default DownloadAction;
