import React, {useCallback, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {useParams} from 'react-router';

import {Breadcrumbs, Link, LoadingFrame, SearchBar} from '../../../components';
import { formatBytes } from '../../../infrastructure/helpers/helpers';
import {getFoldersListRequest, searchFoldersListRequest, clearFoldersState} from '../state/actions';
import {selectFolderData, selectFolderMetaData} from '../state/selectors';
import Header from './Header';
import FoldersComponent from "./folder/FoldersComponent";
import FilesComponent from "./file/FilesComponent";
import {FileManagerRouteGetter} from "../infrastructure/routeGetter";
import {RouteService} from "../infrastructure/routeService";

const FileManager = () => {
  const {'0': routePath} = useParams();
  const { data, loading, error, isSearch } = useSelector(selectFolderData);
  const folderMetaData = useSelector(selectFolderMetaData);
  const dispatch = useDispatch();

  const fetch = useCallback((path: string) => {
    if (!path && typeof routePath === 'string' && routePath !== '/') {
      dispatch(getFoldersListRequest(routePath));
    } else {
      dispatch(searchFoldersListRequest({
        path: path,
        pathPosition: folderMetaData.level + 1,
      }));
    }
  }, [dispatch, folderMetaData, routePath]);

  useEffect(() => {
    if (typeof routePath === 'string' && routePath.trim() !== '') {
      if (!loading && !error) {
        const folderPath = RouteService.getFolderPath(routePath);

        if (data === null || (!isSearch && folderMetaData.path !== folderPath)) {
          dispatch(getFoldersListRequest(routePath));
        }
      }
    }
  }, [dispatch, data, error, loading, routePath, folderMetaData, isSearch]);

  const totalSize = useMemo(() => {
    let totalBytes = 0;

    if (data !== null && data?.totalSizeByte) {
      totalBytes = data.totalSizeByte;
    }

    return formatBytes(totalBytes);
  }, [data?.totalSizeByte]);

  const date = useMemo(() => {
    if (data !== null) {
      return moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss');
    }
    return '';
  }, [data?.createdAt]);

  const breadcrumbs = useMemo(() => {
    const result: any[] = [];
    if (typeof routePath === "string") {
      const paths = routePath.split('/');
      let to = '';
      paths.forEach((item) => {
        if (item) {
          if (!RouteService.isFilePath(item)) {
            to += `/${item}`;
            result.push({
              title: item,
              onClick: () => dispatch(getFoldersListRequest(to)),
              to: FileManagerRouteGetter.getFolderPath(to),
            });
          }
        }
      });
    }

    return result;
  }, [routePath, dispatch]);

  const toRootDir = useCallback(() => {
    dispatch(clearFoldersState());
  }, [dispatch]);

  return (
    <>
      {loading && <LoadingFrame />}
      <Link to={FileManagerRouteGetter.index} onClick={toRootDir} style={{fontSize: 20}}>
        Главная
      </Link>
      <SearchBar fetch={fetch} />
      {(data !== null && !error) && (
        <>
          <Header date={date} totalSize={totalSize} folderName={folderMetaData.folderName} />
          <Breadcrumbs items={breadcrumbs} />
          {(!!data?.children?.length) && (<FoldersComponent data={data} />)}
          {(!!data?.files?.length) && (<FilesComponent files={data.files} />)}
        </>
      )}
    </>
  );
};

export default FileManager;
