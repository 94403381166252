import { createStyles, makeStyles } from '@material-ui/core';

const FileStyles = makeStyles(createStyles({
  row: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(1, 179, 197, 0.08)',
    },
  },
  imageIcon: {
    verticalAlign: 'middle',
    color: '#01B3C5',
    marginRight: 10,
  },
  gallery: {
    height: 'calc(100vh - 80px)',
    width: 'calc(100% - 100px)',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
    },
  },
  paperRoot: {
    background: 'rgba(0,0,0,0.85)',
  },
  checkBoxRoot: {
    '&:hover': {
      backgroundColor: 'rgba(38, 50, 95, 0.15) !important',
    }
  }
}), { name: 'FilesDashboard' });

export default FileStyles;
