import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import auth from '../context/auth/state';
import common from './context/common/reducers';
import notifications from './context/notifications/reducers';
import fileManager from '../context/file-manager/state';

const appState = (history: History) => combineReducers({
  router: connectRouter(history),
  auth,
  common,
  fileManager,
  notifications,
});

export default appState;
