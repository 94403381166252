import { createContext, useState } from 'react';

interface FilesViewerContextType {
  index: number;
  setIndex: (index: number) => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const initialState = {
  index: 0,
  setIndex: () => {},
  isOpen: false,
  setIsOpen: () => {},
};

export const FilesViewerContext = createContext<FilesViewerContextType>(initialState);

export const useFileViewer = (): FilesViewerContextType => {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return {
    index,
    setIndex,
    isOpen,
    setIsOpen,
  };
};
