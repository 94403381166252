import React from 'react';
import {
  Breadcrumbs,
  Grid,
} from '@material-ui/core';

import { Link } from '../index';

type Props = {
  items: Array<{
    title: React.ReactNode;
    to: string;
    onClick?: () => void;
  }>;
};

const BreadcrumbsUI = ({ items }: Props) => {
  return (
    <Grid container style={{ margin: '10px 0' }}>
      <Breadcrumbs>
        {items.map((item, index) => {
          // if (url === item.to) {
          //   return (
          //     <Typography display="inline" color="textPrimary" key={index}>
          //       {item.title}
          //     </Typography>
          //   );
          // }
          return (
            <Link to={item.to} onClick={item.onClick} key={index}>
              {item.title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
};

export default BreadcrumbsUI;
