import React, { useEffect, useCallback } from 'react';
import { useSnackbar, VariantType, SnackbarMessage } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';

import Icons from './Icons';
import { NotificationIcon } from '../../state/context/notifications/types';
import { selectNotificationsList } from '../../state/context/notifications/selectors';
import { removeNotifications } from '../../state/context/notifications/actions';

const makeTypeSet = (color: string) => ({
  color,
  background: '#FFF',
  borderBottomColor: color,
});

const useTypeStyles = makeStyles(({ palette }: Theme) => createStyles({
  default: makeTypeSet(palette.text.primary),
  success: makeTypeSet('#01B3C5'),
  error: makeTypeSet('#F8221A'),
  warning: makeTypeSet('#F8221A'),
  info: makeTypeSet('#2979FF'),
}), { name: 'NotificationType' });

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => createStyles({
  message: {
    width: '350px',
    [breakpoints.down('xs')]: {
      maxWidth: '280px',
    },
  },
  icon: {
    fontSize: 20,
    marginRight: spacing(1),
    verticalAlign: 'middle',
  },
  closeIcon: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1000,
    height: 20,
    width: 20,
    borderRadius: '50%',
    color: '#597982',
    '&:hover': {
      cursor: 'pointer',
      color: '#597982',
    },
  },
  closeDefaultIcon: {
    color: '#FFF',
    '&:hover': {
      cursor: 'pointer',
      color: '#597982',
    },
  },
  action: {
    alignItems: 'end',
    height: '100%',
  },
}), { name: 'Notification' });

type Props = {
  message: SnackbarMessage;
  type: VariantType;
  onClose: () => void;
  icon: NotificationIcon;
};

const NotificationElement = ({
  message, type, icon, onClose,
}: Props) => {
  const classes = useStyles();
  const typeClass = useTypeStyles()[type];
  const Icon = Icons[icon];

  return (
    <span className={classes.message}>
      <Icon className={clsx(classes.icon, typeClass)} />
      {message}
      <CloseIcon
        key="close"
        aria-label="close"
        color="inherit"
        className={classes.closeIcon}
        onClick={onClose}
      />
    </span>
  );
};

const Notification = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectNotifications = useSelector(selectNotificationsList);
  const handleClick = useCallback((id: number | string) => () => closeSnackbar(id), [closeSnackbar]);

  useEffect(() => {
    const hasNotifications = selectNotifications.length !== 0;
    if (hasNotifications) {
      selectNotifications.forEach((notify) => {
        const id = uuid();
        const variant = notify.type;
        const icon = notify.icon === undefined ? notify.type : notify.icon;
        enqueueSnackbar(
          <NotificationElement message={notify.message} type={notify.type} icon={icon} onClose={handleClick(id)} />,
          {
            key: id,
            variant,
          },
        );
      });
      dispatch(removeNotifications());
    }
  }, [dispatch, classes, enqueueSnackbar, handleClick, selectNotifications]);

  return null;
};

export default Notification;
