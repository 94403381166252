import { action, withPayload, withoutPayload } from '../../../state/utils/redux';
import {ClearFoldersRequest, DownloadArchiveRequest, SearchFolderRequest} from '../infrastructure/obligations/request';
import {FolderEntity} from "../infrastructure/obligations/folderEntity";
import {FolderMetaData} from "../infrastructure/obligations/folderMetaData";

export const getFoldersListRequest = action(
  'GET_FOLDERS_LIST_DASHBOARD_REQUEST',
  withPayload<string>(),
);

export const getFoldersListSuccess = action(
  'GET_FOLDERS_LIST_DASHBOARD_SUCCESS',
  withPayload<FolderEntity>(),
);

export const getFoldersListFail = action(
  'GET_FOLDERS_LIST_DASHBOARD_FAIL',
  withPayload<string>(),
);

export const searchFoldersListRequest = action(
  'SEARCH_FOLDERS_LIST_DASHBOARD_REQUEST',
  withPayload<SearchFolderRequest>(),
);

export const searchFoldersListSuccess = action(
  'SEARCH_FOLDERS_LIST_DASHBOARD_SUCCESS',
  withPayload<FolderEntity>(),
);

export const searchFoldersListFail = action(
  'SEARCH_FOLDERS_LIST_DASHBOARD_FAIL',
  withPayload<string>(),
);

export const clearFoldersState = action(
  'CLEAR_FOLDERS_LIST_DASHBOARD',
  withoutPayload,
);

export const clearFoldersListRequest = action(
  'CLEAR_FOLDERS_LIST_DASHBOARD_REQUEST',
  withPayload<ClearFoldersRequest>(),
);

export const clearFoldersListSuccess = action(
  'CLEAR_FOLDERS_LIST_DASHBOARD_SUCCESS',
  withoutPayload,
);

export const clearFoldersListFail = action(
  'CLEAR_FOLDERS_LIST_DASHBOARD_FAIL',
  withPayload<string>(),
);

export const clearFilesList = action(
  'CLEAR_FILES_LIST_DASHBOARD',
  withoutPayload,
);

export const setCurrentFolderMetaData = action(
  'SET_CURRENT_FOLDER_META_DATA',
  withPayload<FolderMetaData>(),
)

export const setSearchFolderData = action(
  'SET_SEARCH_FOLDER_DATA',
  withPayload<boolean>(),
)

export const deleteManyFilesRequest = action(
  'DELETE_MANY_FILES_REQUEST',
  withPayload<number[]>(),
);
export const deleteManyFilesSuccess = action(
  'DELETE_MANY_FILES_SUCCESS',
  withPayload<number[]>(),
);
export const deleteManyFilesFail = action(
  'DELETE_MANY_FILES_FAIL',
  withoutPayload,
);

export const downloadFolderArchive = action(
  'DOWNLOAD_FOLDER_ARCHIVE',
  withPayload<DownloadArchiveRequest>(),
);
