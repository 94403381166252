import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import {API_URL} from "./obligations/constants";

type Headers = {
  Authorization?: string;
};

type Config = AxiosRequestConfig & {
  headers?: Headers;
  params?: Record<string, string | number>;
};

export class Requester {
  private config = {
    headers: {},
    params: {},
  } as Config;

  private axios = axios.create({
    baseURL: API_URL,
    paramsSerializer: (data: object) => qs.stringify(data, { arrayFormat: 'brackets' }),
  });

  public setToken(token: string | null) {
    const { Authorization, ...rest } = this.config.headers;

    this.config.headers = {
      ...rest,
      ...(token && { Authorization: `Bearer ${token}` }),
    };
  }

  public get<T>(uri: string, params = {}) {
    return this.axios
      .get<T>(uri, this.mergeConfigs(params))
      .then((response) => response.data);
  }

  public post<T>(uri: string, data: unknown, params = {}) {
    return this.axios
      .post<T>(uri, data, this.mergeConfigs(params))
      .then((response) => response.data);
  }

  public put<T>(uri: string, data: unknown, params = {}) {
    return this.axios
      .put<T>(uri, data, this.mergeConfigs(params))
      .then((response) => response.data);
  }

  public delete<T = void>(uri: string, data = {}, params = {}) {
    return this.axios
      .delete(uri, this.mergeConfigs({data, ...params}))
      .then((response) => response.data as T);
  }

  private mergeConfigs(params: Config['params']) {
    return {
      ...this.config,
      params: { ...this.config.params, ...params },
    };
  }
}

export default new Requester();
