
export const FileExtensions = [
  'jpg',
  'JPG',
  'png',
  'PNG',
  'jpeg',
  'JPEG',
  'bmp',
  'BMP',
  'gif',
  'GIF',
  'tif',
  'TIF',
  'tiff',
  'TIFF',
  'svg',
  'SVG',
  'webp',
  'WEBP',
  'heif',
  'HEIF',
];
