import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router';
import { push } from 'connected-react-router';

import { selectAuth } from '../../context/auth/state/selectors';
import { setPreviousRoute } from '../../state/context/common/actions';
import { RouteComponent } from '../../infrastructure/obligations/routeComponent';
import {PagesRouteGetter} from '../../routes/routeGetter';
import { LoadingFrame } from '../index';

const ProtectedRoute = (props: RouteComponent) => {
  const auth = useSelector(selectAuth);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.tokens.access_token && !auth.loading) {
      dispatch(setPreviousRoute(`${location.pathname}${location.search}`));
      dispatch(push(PagesRouteGetter.login));
    }
  }, [auth, location, dispatch]);

  if (auth.loading) {
    return (
      <LoadingFrame />
    );
  }

  if (!auth.tokens.access_token) {
    return null;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
