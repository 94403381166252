import React, {useCallback, useEffect} from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid, Typography,
} from '@material-ui/core';

import { FilesViewerContext, useFileViewer } from '../../../../infrastructure/hooks/FilesViewerContext';
import TableToolbar from './Toolbar';
import FilesHead from './FilesHead';
import FileRow from './FileRow';
import FileViewer from './FileViewer';
import {FileEntity} from "../../infrastructure/obligations/fileEntity";

type Props = {
  files: FileEntity[];
};

const FilesComponent = ({ files }:Props) => {
  const filesContext = useFileViewer();

  const [selected, setSelected] = React.useState<number[]>([]);

  useEffect(() => {
    if (files.length) {
      setSelected(
        (prevState) => prevState.filter((fileId) => files.find((file) => file.id === fileId))
      );
    }
  }, [files, setSelected]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = files.map((item) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = useCallback(
    (itemId: number) => () => {
        const selectedIndex = selected.indexOf(itemId);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, itemId);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }

        setSelected(newSelected);
      },
    [selected, setSelected]
  );

  const isSelected = useCallback(
    (itemId: number) => selected.indexOf(itemId) !== -1,
    [selected]
  );

  return (
    <Grid container style={{marginTop: 20}} spacing={3}>
      <FilesViewerContext.Provider value={filesContext}>
        <FileViewer files={files} />
        <Paper style={{ width: '100%' }}>
          <TableToolbar selected={selected} />
          <Typography variant="h6" style={{marginLeft: 20}}>
            Список файлов
          </Typography>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <FilesHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={files.length}
              />
              <TableBody>
                {files.map((item, index) => (
                  <FileRow
                    file={item}
                    fileIndex={index}
                    isSelected={isSelected(item.id)}
                    handleSelect={handleSelect(item.id)}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </FilesViewerContext.Provider>
    </Grid>
  );
};

export default FilesComponent;
