import { RouteProps } from 'react-router';

import { NotFoundPage } from '../components';
import PageLayout from '../layouts/PageLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import Login from '../context/auth/view/Login';
import {PagesRouteGetter} from './routeGetter';
import { RouteComponent } from '../infrastructure/obligations/routeComponent';

export const notFoundPage = {
  path: PagesRouteGetter.notFound,
  component: NotFoundPage,
} as RouteComponent;

const login = {
  path: PagesRouteGetter.login,
  component: Login,
  exact: true,
} as RouteComponent;

export const dashboard = {
  path: '/zeus',
  component: DashboardLayout,
} as RouteProps;

export const root = {
  path: '/',
  component: PageLayout,
} as RouteProps;


export default [
  login,
  notFoundPage,
  dashboard,
  root,
] as const;
