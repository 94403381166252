import { RouteComponent } from '../infrastructure/obligations/routeComponent';
import {PagesRouteGetter} from './routeGetter';

export const notFoundRoute = {
  path: '*',
  redirect: PagesRouteGetter.notFound,
  exact: false,
} as RouteComponent;

export default [
  notFoundRoute,
] as RouteComponent[];
