import { createContext, useState } from 'react';

interface ActionMenuContextType {
  isOpen: boolean;
  setOpen: (state: boolean) => void;
}

const initialState = {
  isOpen: false,
  setOpen: () => {},
};

export const ActionMenuContext = createContext<ActionMenuContextType>(initialState);

export const useActionMenuContext = (): ActionMenuContextType => {
  const [isOpen, setOpen] = useState(false);

  return {
    isOpen,
    setOpen,
  };
};
