import React, { useRef, useCallback } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useActionMenuContext, ActionMenuContext } from './ActionMenuContext';
import { useId } from '../../infrastructure/hooks';

type Props = {
  children: React.ReactNode;
};

const ActionMenu = ({ children }: Props) => {
  const id = useId();
  const anchorEl = useRef<null | HTMLButtonElement>(null);
  const context = useActionMenuContext();
  const { isOpen, setOpen } = context;

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
  }, [setOpen]);

  return (
    <ActionMenuContext.Provider value={context}>
      <div>
        <IconButton
          aria-label="more"
          aria-controls={id}
          aria-haspopup="true"
          onClick={handleClick}
          ref={anchorEl}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={id}
          anchorEl={anchorEl.current}
          // keepMounted
          open={isOpen}
          onClose={handleClose}
        >
          {children}
        </Menu>
      </div>
    </ActionMenuContext.Provider>
  );
};

export default ActionMenu;
