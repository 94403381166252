import { takeLatest, put, select } from 'redux-saga/effects';

import history from '../../history';
import {PagesRouteGetter} from '../../../routes/routeGetter';
import { loginSuccess, initAuthSuccess, initAuth } from '../../../context/auth/state/actions';
import { setPreviousRoute, initApp, initSuccess } from './actions';
import { selectPreviousRoute } from './selectors';
import {FileManagerRouteGetter} from "../../../context/file-manager/infrastructure/routeGetter";

function* init() {
  yield put(setPreviousRoute(`${window.location.pathname}${window.location.search}`));
  yield put(initAuth());
  yield put(initSuccess());
}

function* login() {
  try {
    const previousRoute = yield select(selectPreviousRoute);
    if (previousRoute && previousRoute !== PagesRouteGetter.login) {
      history.push(previousRoute);
    } else {
      history.push(FileManagerRouteGetter.getFolderPath(''));
    }
    yield put(setPreviousRoute(''));
  } catch (e) {
    console.error(e);
  }
}

export default [
  takeLatest(initApp.type, init),
  takeLatest(initAuthSuccess.type, login),
  takeLatest(loginSuccess.type, login),
];
