import React from 'react';
import {
  makeStyles,
  createStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(createStyles({
  wrapper: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
}), { name: 'LoadingFrame' });

const LoadingFrame = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <CircularProgress />
    </Grid>
  );
};

export default LoadingFrame;
