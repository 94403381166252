import { notify, removeNotifications } from '../actions';
import { Notification, NotificationAction } from '../types';

type State = Notification[];

const defaultState = [] as State;

export default (state = defaultState, action: NotificationAction): State => {
  switch (action.type) {
    case notify.type:
      return [...state, action.payload];
    case removeNotifications.type:
      return defaultState;

    default:
      return state;
  }
};
