import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Grid, Button, Typography } from '@material-ui/core';

import { selectAuth } from '../state/selectors';
import { loginRequest } from '../state/actions';
import { LoadingFrame } from '../../../components';

const Login = () => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { loading, error } = useSelector(selectAuth);

  useEffect(() => {
    if (login.trim() === '' || password.trim() === '') {
      if (isValid) {
        setIsValid(false);
      }
    } else if (!isValid) {
      setIsValid(true);
    }
  }, [isValid, setIsValid, login, password]);

  const onChangeLogin = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  }, [setLogin]);

  const onChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, [setPassword]);

  const loginHandler = useCallback(() => {
    dispatch(loginRequest({
      username: login,
      password,
    }));
  }, [dispatch, password, login]);

  useEffect(() => {
    const enterHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (isValid) {
          loginHandler();
        }
      }
    };
    window.addEventListener('keydown', enterHandler);

    return () => {
      window.removeEventListener('keydown', enterHandler);
    }
  }, [isValid, loginHandler]);

  return (
    <>
      {loading && <LoadingFrame />}
      <Grid container alignItems="center" style={{ height: '100vh' }}>
        <Grid container alignItems="center" spacing={3} style={{ maxWidth: 400, margin: 'auto' }}>
          <Grid item xs={12}>
            <TextField
              value={login}
              onChange={onChangeLogin}
              label="Логин"
              autoFocus
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={password}
              onChange={onChangePassword}
              label="Пароль"
              type="password"
              fullWidth
              required
            />
          </Grid>
          <Grid item style={{ margin: 'auto' }}>
            <Button
              onClick={loginHandler}
              disabled={!isValid || loading}
              color="primary"
              variant="contained"
            >
              Войти
            </Button>
          </Grid>
          {error && (
            <Grid item container justify="center">
              <Typography color="error">Неверный логин или пароль</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>

  );
};

export default Login;
