import React, { useCallback } from 'react';
import { Link as MuiLink, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createStyles, makeStyles } from '@material-ui/styles';

type Props = Omit<React.ComponentProps<typeof MuiLink>, 'href' | 'target'> & {
  to?: string;
  replace?: boolean;
  target?: '_self' | '_blank' | '_top';
  defaultAction?: boolean;
};

const useStyles = makeStyles(({ palette }: Theme) => createStyles({
  root: {
    outline: 'unset',
    color: '#26325F',
  },
}), { name: 'Link' });

const Link = ({
  to,
  replace: shouldReplace = false,
  children,
  target = '_self',
  onClick,
  defaultAction = false,
  ...props
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (!e.defaultPrevented && to && !defaultAction) {
      dispatch(shouldReplace ? replace(to) : push(to));
    }
    if (!defaultAction) {
      e.preventDefault();
    }
  }, [onClick, dispatch, shouldReplace, to, defaultAction]);

  return (
    <MuiLink
      tabIndex={-1}
      href={to || '#'}
      target={target}
      onClick={handleClick}
      classes={{
        root: classes.root,
      }}
      className={classes.root}
      {...props}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
