import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import appState from './appState';
import rootSaga from './sagas';
import history from './history';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const sagaMiddleware = createSagaMiddleware();

const appStore = createStore(
  appState(history),
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default appStore;
