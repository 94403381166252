import {put, takeLatest, select} from 'redux-saga/effects';

import CarDashboardService from './folderRequester';
import {
  getFoldersListRequest,
  getFoldersListSuccess,
  getFoldersListFail,
  clearFoldersState,
  clearFoldersListRequest,
  clearFoldersListSuccess,
  clearFoldersListFail,
  searchFoldersListRequest,
  searchFoldersListSuccess,
  searchFoldersListFail,
  setCurrentFolderMetaData,
  clearFilesList,
  deleteManyFilesRequest,
  deleteManyFilesSuccess,
  deleteManyFilesFail,
  downloadFolderArchive,
  setSearchFolderData,
} from './actions';
import { apiCallAuth } from '../../../state/utils/effects';
import { notify } from '../../../state/context/notifications/actions';
import { logOut } from '../../auth/state/actions';
import { getErrorNotification } from '../../../state/utils/helpers';
import FileManagerService from "./folderRequester";
import {selectFolderMetaData} from "./selectors";

function* getFoldersList({ payload }: ReturnType<typeof getFoldersListRequest>) {
  try {
    const data = yield apiCallAuth(CarDashboardService.getByPath, payload);
    yield put(setCurrentFolderMetaData({
      level: data.level || 0,
      path: data.path || '/',
      folderName: data.folderName || '/',
      folderId: data.folderId,
    }));
    yield put(getFoldersListSuccess(data));
  } catch (e) {
    yield put(notify(getErrorNotification(e)));
    yield put(getFoldersListFail(e));
  }
}

function* searchFoldersList({ payload }: ReturnType<typeof searchFoldersListRequest>) {
  try {
    const data = yield apiCallAuth(CarDashboardService.search, payload);
    yield put(setSearchFolderData(true));
    yield put(searchFoldersListSuccess(data));
    yield put(setSearchFolderData(false));
  } catch (e) {
    yield put(notify(getErrorNotification(e)));
    yield put(searchFoldersListFail(e));
  }
}

function* clearFolders({ payload }: ReturnType<typeof clearFoldersListRequest>) {
  try {
    const metaData = yield select(selectFolderMetaData);
    const response = yield apiCallAuth(CarDashboardService.clearFolders, payload);
    const successIds = response
      .filter((item: any) => item.isDeleted)
      .map((item: any) => item.id);
    yield put(clearFoldersListSuccess());
    yield put(notify({
      type: 'success',
      message: `Папок удалено: ${successIds.length} из ${payload.folders.length}.`,
    }));
    yield put(getFoldersListRequest(metaData.path));
  } catch (e) {
    yield put(notify(getErrorNotification(e)));
    yield put(clearFoldersListFail(e));
  }
}

function* downloadArchive({ payload }: ReturnType<typeof downloadFolderArchive>) {
  try {
    yield apiCallAuth(FileManagerService.downloadArchive, payload);
  } catch (e) {
    yield put(notify(getErrorNotification(e)));
    console.log(e);
  }
}

function* deleteManyFiles({ payload }: ReturnType<typeof deleteManyFilesRequest>) {
  try {
    const response = yield apiCallAuth(FileManagerService.deleteManyFiles, payload);
    const successIds = response
      .filter((item: any) => item.result === 'ok')
      .map((item: any) => item.id);

    yield put(deleteManyFilesSuccess(successIds));
    yield put(notify({
      type: 'success',
      message: `Файлов удалено: ${successIds.length} из ${payload.length}.`,
    }));

  } catch (e) {
    console.error(e);
    yield put(deleteManyFilesFail());
    yield put(notify(getErrorNotification(e)));
  }
}

function* logout() {
  yield put(clearFilesList());
  yield put(clearFoldersState());
}

export default [
  takeLatest(getFoldersListRequest.type, getFoldersList),
  takeLatest(searchFoldersListRequest.type, searchFoldersList),
  takeLatest(clearFoldersListRequest.type, clearFolders),
  takeLatest(downloadFolderArchive.type, downloadArchive),
  takeLatest(deleteManyFilesRequest.type, deleteManyFiles),
  takeLatest(logOut.type, logout),
];
