import React from 'react';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import FileStyles from './styles';

interface Props {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const FoldersHead = (props: Props) => {
  const styles = FileStyles();

  const { onSelectAllClick, numSelected, rowCount } = props;
  const isChecked = rowCount > 0 && numSelected === rowCount;
  const isIndeterminate = numSelected > 0 && numSelected < rowCount;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={isIndeterminate}
            checked={isChecked}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
            classes={{
              root: styles.checkBoxRoot,
              checked: styles.checkBoxRoot,
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6">Список директорий:</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FoldersHead;
