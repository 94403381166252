import { RouteComponent } from '../../infrastructure/obligations/routeComponent';
import FileManager from './view';
import {FileManagerRouteGetter} from './infrastructure/routeGetter';

export const fileManagerRouteComponent = {
  path: [
    FileManagerRouteGetter.index,
    FileManagerRouteGetter.getFolderPath('*')
  ],
  component: FileManager,
} as RouteComponent;
