import { all } from 'redux-saga/effects';

import carDashboardSaga from '../context/file-manager/state/sagas';
import authSaga from '../context/auth/state/sagas';
import commonSaga from './context/common/sagas';

export default function* appSaga() {
  yield all([
    ...authSaga,
    ...carDashboardSaga,
    ...commonSaga,
  ]);
}
