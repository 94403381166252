import { action, withPayload, withoutPayload } from '../../utils/redux';

export const setPreviousRoute = action(
  'SET_PREVIOUS_ROUTE',
  withPayload<string>(),
);

export const initApp = action(
  'INIT_APP',
  withoutPayload,
);

export const initSuccess = action(
  'INIT_APP_SUCCESS',
  withoutPayload,
);
