import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';


const Icons = {
  default: SvgIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export default Icons;
