import { combineReducers } from 'redux';
import { Reducer } from '../../../state/types';
import { AuthState, AuthAction } from './types';
import {
  loginRequest,
  loginSuccess,
  loginFail,
  logOut,
  initAuthSuccess,
  initAuth,
  initAuthFail,
} from './actions';

type ReducerOf<T extends keyof AuthState> = Reducer<AuthState, AuthAction, T>;

const tokens: ReducerOf<'tokens'> = (
  state = {
    access_token: '',
    refresh_token: '',
  },
  action,
) => {
  switch (action.type) {
    case loginSuccess.type:
    case initAuthSuccess.type:
      return action.payload;

    case logOut.type:
      return {
        access_token: '',
        refresh_token: '',
      };

    default:
      return state;
  }
};

const loading: ReducerOf<'loading'> = (state = false, action) => {
  switch (action.type) {
    case loginRequest.type:
    case initAuth.type:
      return true;

    case loginSuccess.type:
    case loginFail.type:
    case initAuthSuccess.type:
    case initAuthFail.type:
      return false;

    default:
      return state;
  }
};

const error: ReducerOf<'error'> = (state = '', action) => {
  switch (action.type) {
    case loginRequest.type:
      return '';

    case loginFail.type:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  tokens,
  loading,
  error,
});
