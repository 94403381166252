import React, { useCallback, useContext } from 'react';
import { MenuItem } from '@material-ui/core';

import { ActionMenuContext } from './ActionMenuContext';

type Props = {
  onClick: () => void;
  children: React.ReactNode;
};

const ActionItem = React.forwardRef<HTMLLIElement, Props>(({
  onClick,
  children,
}: Props, ref) => {
  const { setOpen } = useContext(ActionMenuContext);

  const onClickHandler = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
    setOpen(false);
  }, [onClick, setOpen]);


  return (
    <MenuItem onClick={onClickHandler} ref={ref}>
      {children}
    </MenuItem>
  );
});

export default ActionItem;
