import React, {useCallback, useEffect} from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
} from '@material-ui/core';

import { FolderEntity } from '../../infrastructure/obligations/folderEntity';
import TableToolbar from './Toolbar';
import FoldersHead from './FoldersHead';
import FolderRow from './FolderRow';

type Props = {
  data: FolderEntity;
};

const FoldersComponent = ({ data }:Props) => {
  const [selectedFolder, setSelectedFolder] = React.useState<number[]>([]);

  useEffect(() => {
    if (Array.isArray(data.children) && data.children.length) {
      setSelectedFolder(
        (prevState) => prevState.filter((folderId) => data.children?.find((folder) => folder.folderId === folderId))
      );
    }
  }, [data, setSelectedFolder]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelecteds = data?.children?.map((item) => item.folderId);
          setSelectedFolder(newSelecteds || []);
        return;
      }
      setSelectedFolder([]);
    },
    [setSelectedFolder, data],
  );

  const handleSelectFolder = useCallback(
    (itemId: number) => () => {
        const selectedIndex = selectedFolder.indexOf(itemId);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedFolder, itemId);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedFolder.slice(1));
        } else if (selectedIndex === selectedFolder.length - 1) {
          newSelected = newSelected.concat(selectedFolder.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedFolder.slice(0, selectedIndex),
            selectedFolder.slice(selectedIndex + 1),
          );
        }

        setSelectedFolder(newSelected);
      },
    [selectedFolder, setSelectedFolder]
  );

  const isSelected = useCallback(
    (itemId: number) => selectedFolder.indexOf(itemId) !== -1,
    [selectedFolder]
  );

  return (
    <Grid container spacing={3} style={{ marginTop: 0 }}>
      <Paper style={{ width: '100%' }}>
        <TableToolbar selected={selectedFolder} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <FoldersHead
              numSelected={selectedFolder.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data?.children?.length || 0}
            />
            <TableBody>
              {data?.children?.map((item, index) => (
                <FolderRow
                  folder={item}
                  folderIndex={index}
                  isSelected={isSelected(item.folderId)}
                  handleSelect={handleSelectFolder(item.folderId)}
                  key={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default FoldersComponent;
