import React from 'react';
import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import styles from './styles';

type Props = {
  onClick: () => void;
};

const NextAction = ({ onClick }: Props) => {
  const classes = styles();

  return (
    <IconButton onClick={onClick} className={classes.button}>
      <ChevronRight className={classes.icon} />
    </IconButton>
  );
};

export default NextAction;
