import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';

import { ModalConfirm } from '../../../../../components';
import { clearFoldersListRequest } from '../../../state/actions';

import useStyles from './styles';


interface PropsButton {
  selected: number[];
}

const RemoveSelectedFoldersAction = ({ selected }: PropsButton) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmOpened, setConfirmOpened] = useState(false);

  const deleteSelectedFiles = useCallback(() => {
    if (selected.length) {
      dispatch(clearFoldersListRequest({
        folders: selected,
      }));
    }
  }, [selected, dispatch]);

  const clickHandler = useCallback(() => {
    setConfirmOpened(true);
  }, [setConfirmOpened]);

  const closeHandler = useCallback(() => {
    setConfirmOpened(false);
  }, [setConfirmOpened]);

  const confirmHandler = useCallback(() => {
    closeHandler();
    deleteSelectedFiles();
  }, [closeHandler, deleteSelectedFiles]);

  return (
    <>
      <Tooltip title="Очистить">
        <IconButton
          aria-label="delete"
          onClick={clickHandler}
          classes={{
            root: classes.buttonRoot,
          }}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </Tooltip>
      <ModalConfirm
        open={confirmOpened}
        onClose={closeHandler}
        onCancel={closeHandler}
        onConfirm={confirmHandler}
        text={`Очистить?`}
      />
    </>
  );
};

export default RemoveSelectedFoldersAction;
