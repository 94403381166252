import { Notification } from './types';
import { action, withoutPayload, withPayload } from '../../utils/redux';

export const notify = action(
  'ENQUEUE_NOTIFICATION',
  withPayload<Notification>(),
);

export const removeNotifications = action(
  'REMOVE_NOTIFICATIONS',
  withoutPayload,
);
