import React from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(createStyles({
  wrapper: {
    height: '100vh',
  },
}), { name: 'NotFoundPage' });

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" className={classes.wrapper}>
      <Typography variant="h2">
        Page Not Found 404
      </Typography>
    </Grid>
  );
};

export default NotFoundPage;
