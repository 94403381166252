import { NotificationType, Notification } from '../context/notifications/types';
import { ResponseErrorType } from '../types';


export const getErrorNotification = (
  error: ResponseErrorType, type: NotificationType = 'error',
): Notification => ({
  message: error.response?.data?.error?.message || error.response?.data?.error || error.message || 'An Error Occurred',
  type,
});
