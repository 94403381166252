import React from 'react';
import {Paper, Toolbar, Typography} from '@material-ui/core';

import RemoveSelectedFilesAction from './actions/RemoveSelectedFilesAction';

interface Props {
  selected: number[];
}

const TableToolbar = ({ selected }: Props) => {

  return (
    <Toolbar style={{ minHeight: 48 }}>
      {selected.length > 0 && (
        <Typography color="inherit" variant="subtitle1" component="div">
          {selected.length} выбрано
        </Typography>
      )}
      {selected.length > 0 && (
        <RemoveSelectedFilesAction selected={selected} />
      )}
    </Toolbar>
  );
};

export default TableToolbar;
