import { combineReducers } from 'redux';
import { Reducer } from '../../types';
import { CommonAction, CommonState } from './types';
import { setPreviousRoute, initSuccess } from './actions';

type ReducerOf<T extends keyof CommonState> = Reducer<CommonState, CommonAction, T>;

const isInited: ReducerOf<'isInited'> = (state = false, action) => {
  switch (action.type) {
    case initSuccess.type:
      return true;

    default:
      return state;
  }
};

const previousRoute: ReducerOf<'previousRoute'> = (
  state = '',
  action,
) => {
  switch (action.type) {
    case setPreviousRoute.type:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  isInited,
  previousRoute,
});
