import React, { useCallback } from 'react';
import {Checkbox, Grid, TableCell, TableRow, Typography} from '@material-ui/core';
import { PermMediaOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { formatBytes } from '../../../../infrastructure/helpers/helpers';

import useStyles from './styles';
import {getFoldersListRequest} from "../../state/actions";
import {FileManagerRouteGetter} from "../../infrastructure/routeGetter";

type Props = {
  folder: any;
  isSelected: boolean;
  handleSelect: () => void;
  folderIndex: number;
};

const FolderRow = ({ folder, folderIndex, isSelected, handleSelect }: Props) => {
  const dispatch = useDispatch();

  const styles = useStyles();
  const labelId = `enhanced-table-checkbox-${folderIndex}`;
  const folderPath = folder.path;

  const onClickHandler = useCallback(() => {
    dispatch(
      push(
        FileManagerRouteGetter.getFolderPath(folderPath),
      ),
    );
    dispatch(getFoldersListRequest(folderPath));
  }, [dispatch, folderPath])

  return (
    <TableRow
      onClick={onClickHandler}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={folder.folderId}
      selected={isSelected}
      className={styles.row}
    >
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onChange={handleSelect}
          checked={isSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          classes={{
            root: styles.checkBoxRoot,
            checked: styles.checkBoxRoot,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Grid item xs={12}>
          <Typography display="inline">
            <PermMediaOutlined className={styles.folderIcon} />
          </Typography>
          <Typography display="inline">{folder.folderName}</Typography>
        </Grid>
        <Grid item xs={12} className={styles.secondaryText}>
          <Typography display="inline" color="textSecondary">
            Папок: {folder.totalFolders}&nbsp;|&nbsp;
          </Typography>
          <Typography display="inline" color="textSecondary">
            Файлов: {folder.totalFiles}&nbsp;|&nbsp;
          </Typography>
          <Typography display="inline" color="textSecondary">
            (Размер: {formatBytes(folder.totalSizeByte || 0)})
          </Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default FolderRow;
