import { createStyles, makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles(({ palette }: Theme) => createStyles({
  button: {
    color: '#FFF',
    textTransform: 'unset',
    fontSize: 16,
  },
  icon: {
    color: '#FFF',
  },
  deleteIcon: {
    verticalAlign: 'middle',
    color: '#F8221A',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    }
  }
}), { name: 'FoldersAction' });

export default useStyles;
