import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

type Props = {
  onClick: () => void;
};

const CloseAction = ({ onClick }: Props) => {
  const classes = styles();

  return (
    <IconButton aria-label="close" className={classes.button} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseAction;
