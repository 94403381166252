import {FileExtensions} from "./obligations/fileExtension";

export class RouteService {
  static isFilePath(path: string): boolean {
    if (typeof path === 'string') {
      const pathSegments = path.split('.');
      if (pathSegments.length > 1) {
        const ext = pathSegments.pop() || '';
        return FileExtensions.includes(ext);
      }
    }

    return false;
  }

  static getFileName(path: string): string {
    if (typeof path === 'string') {
      let trimmedPath = path.trim();
      if (trimmedPath.endsWith('/')) {
        trimmedPath = trimmedPath.slice(0, -1);
      }
      const pathSegments = trimmedPath.split('/');
      const fileName = pathSegments.pop() || '';
      if (this.isFilePath(fileName)) {
        return fileName;
      }
    }

    return '';
  }

  static getFolderPath(path: string): string {
    if (typeof path === 'string') {
      let trimmedPath = path.trim();
      if (trimmedPath.startsWith('/')) {
        trimmedPath = trimmedPath.substring(1);
      }
      if (trimmedPath.endsWith('/')) {
        trimmedPath = trimmedPath.slice(0, -1);
      }

      const pathSegments = trimmedPath.split('/');
      if (this.isFilePath(path)) {
        pathSegments.pop();
      }

      return pathSegments.join('/')
    }

      return ''
  }
}
