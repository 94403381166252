import { createStyles, makeStyles } from '@material-ui/core';

const FolderStyles = makeStyles(createStyles({
  row: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(1, 179, 197, 0.08)',
    },
  },
  folderIcon: {
    verticalAlign: 'middle',
    color: '#01B3C5',
    marginRight: 10,
  },
  secondaryText: {
    fontSize: '0.8rem',
  },
  checkBoxRoot: {
    '&:hover': {
      backgroundColor: 'rgba(38, 50, 95, 0.15) !important',
    }
  }
}), { name: 'Folder' });

export default FolderStyles;
