import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ActionMenu, ActionItem } from '../index';
import { logOut } from '../../context/auth/state/actions';
import { selectAuth } from '../../context/auth/state/selectors';

const MainBar = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const isAuth = Boolean(auth.tokens.access_token);

  const logOutHandler = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item style={{ marginLeft: 'auto' }}>
        <ActionMenu>
          {isAuth && (
            <ActionItem onClick={logOutHandler}>
              Выйти
            </ActionItem>
          )}
        </ActionMenu>
      </Grid>
    </Grid>
  );
};

export default MainBar;
