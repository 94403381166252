import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import indexRoutes from './routes';
import { initApp } from './state/context/common/actions';
import { selectAppIsInit } from './state/context/common/selectors';
import { Notifications, LoadingFrame } from './components';

const App = () => {
  const dispatch = useDispatch();
  const appIsInit = useSelector(selectAppIsInit);

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  if (!appIsInit) {
    return <LoadingFrame />;
  }

  return (
    <>
      <Notifications />
      <Switch>
        {indexRoutes.map((route) => (
          <Route
            key={Array.isArray(route.path) ? route.path.join(';') : route.path}
            {...route}
          />
        ))}
      </Switch>
    </>
  );
};

export default App;
