import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';

import DownloadAction from './actions/DownloadAction';
import CarStyles from './styles';

type Props = {
  folderName: string;
  date: string;
  totalSize: string;
};

const Header = ({ folderName, date, totalSize }: Props) => {
  const styles = CarStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography display="inline" variant="h4">
          <FolderOpen className={styles.folderTitleIcon} />
        </Typography>
        <Typography display="inline" variant="h4">{folderName}</Typography>
      </Grid>
      <Grid item container justify="space-between">
        <Grid item>
          <Typography>
            Размер: {totalSize}
          </Typography>
          <Typography>
            Изменен: {date}
          </Typography>
        </Grid>
        <Grid item>
          <DownloadAction />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
