import apiService, { Requester } from '../../../infrastructure/requester';
import { LoginRequest } from './loginRequest';

class AuthRequester {
  constructor(private api: Requester) {}

  login = (params: LoginRequest) => this.api.post('/olympus/ory', params);
}

export default new AuthRequester(apiService);
