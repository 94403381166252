import React from 'react';
import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import styles from './styles';

type Props = {
  onClick: () => void;
};

const PreviousAction = ({ onClick }: Props) => {
  const classes = styles();

  return (
    <IconButton onClick={onClick} className={classes.button}>
      <ChevronLeft className={classes.icon} />
    </IconButton>
  );
};

export default PreviousAction;
