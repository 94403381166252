export class FileManagerRouteGetter {
  static readonly index = '/zeus/folder';

  static getFolderPath(path: string) {
    if (path === '') {
      return this.index
    }
    if (path.startsWith('/')) {
      path = path.substring(1);
    }
    return `${this.index}/${path}`;
  }
}
