import jwtDecode from 'jwt-decode';

export const formatBytes = (b: number) => {
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = b;

  while (n >= 1024) {
    n /= 1024;
    l += 1;
  }

  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const downloadFile = async (url: string, name: string) => {
  const a = document.createElement('a');
  a.download = name;
  a.target = '_blank';
  a.href = url;
  a.click();
};

type TokeDto = {
  username: string;
  sub: number;
  iat: number;
  exp: number;
};

export const isAccessToken = (token: string) => {
  if (typeof token === 'string') {
    const { exp } = jwtDecode<TokeDto>(token);
    if (typeof exp === 'number') {
      const timestamp = parseInt((+new Date() / 1000).toString(), 10);
      return timestamp < exp - 10;
    }
  }
  return false;
};
